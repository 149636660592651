import React, { FC } from 'react';
import { Direction } from 'types';
import { DirectionCard, DirectionCardTag, DirectionCardName, ArrowContainer } from './styled';
import { Icon } from 'kl-b2c-ui-kit';

interface DirectionBlockProps {
    item: Direction;
    isClosed: boolean;
    click: () => void;
}

const DirectionBlock: FC<DirectionBlockProps> = ({
    item: {
        name: descriptionName,
        type: { name: typeName },
    },
    click,
}) => (
    <DirectionCard onClick={() => click()}>
        <DirectionCardTag>{typeName}</DirectionCardTag>
        <DirectionCardName>{descriptionName}</DirectionCardName>
        <ArrowContainer>
            <Icon name={'ArrowShortRight'} color={'primary'} size={14} />
        </ArrowContainer>
    </DirectionCard>
);

export default DirectionBlock;
