import { gql } from '@apollo/client';

export const GET_STUDY_TYPES_GQL = gql`
    query getStudyTypes {
        homepageSettings {
            studyTypes {
                id
                name
            }
        }
    }
`;

export default GET_STUDY_TYPES_GQL;
