import { gql } from '@apollo/client';

export const ADD_REGISTER_FORM_DATA_GQL = gql`
    mutation ($model: AddInternshipRegistrationViewModelInput!) {
        internshipRegistrations {
            add(model: $model) {
                id
                registrationCode
                firstName
                lastName
                email
            }
        }
    }
`;

export default ADD_REGISTER_FORM_DATA_GQL;
