import GET_DIRECTIONS from './getDirections';
import GET_INTERNSHIP_STEPS from './getInternshipSteps';
import GET_FEEDBACKS from './getFeedbacks';
import GET_FAQ_QUESTIONS from './getFaqQuestions';
import GET_REGISTER_FORM_CONFIG from './getRegisterFormConfig';
import GET_HOMEPAGE_SETTINGS from './getHomepageSettings';
import GET_EVENT_ADT from './getEventAdt';
import GET_SUBSCRIPTION_FORM_CONFIG from './getSubscriptionFormConfig';
import GET_STUDY_TYPES from './getStudyTypes';
import GET_HOW_DID_YOU_KNOW_TYPES from './getHowDidYouKnowTypes';

export const queryDictionary = {
    GET_DIRECTIONS,
    GET_INTERNSHIP_STEPS,
    GET_FEEDBACKS,
    GET_FAQ_QUESTIONS,
    GET_REGISTER_FORM_CONFIG,
    GET_HOMEPAGE_SETTINGS,
    GET_EVENT_ADT,
    GET_SUBSCRIPTION_FORM_CONFIG,
    GET_STUDY_TYPES,
    GET_HOW_DID_YOU_KNOW_TYPES,
};

export default queryDictionary;
