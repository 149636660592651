import { gql } from '@apollo/client';

export const GET_HOW_DID_YOU_KNOW_TYPES_GQL = gql`
    query getHowDidYouKnowType {
        homepageSettings {
            howDidYouKnowTypes {
                id
                name
            }
        }
    }
`;

export default GET_HOW_DID_YOU_KNOW_TYPES_GQL;
