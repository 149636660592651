import React, { PropsWithChildren, useRef } from 'react';
import { Form, Disclaimer, Hidden } from './styled';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Checkbox, useUtmParams } from 'kl-b2c-ui-kit';
import { useFormContext, Controller, FieldValues } from 'react-hook-form';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useSettings } from 'contexts/settings.context';
import { HeaderButton } from 'styles/common';
import Tou from 'containers/pages/tou/Tou';
import PrivacyPolicy from 'containers/pages/tou/PrivacyPolicy';

type Policy = {
    name: string;
    label: string;
    required?: boolean;
};

type FormBaseProps = {
    gap: string;
    policies: Policy[];
    submit: (data: FieldValues) => void;
    loading?: boolean;
    submitLabel?: string;
    submitButtonWidth?: number;
};

const FormBase = (props: PropsWithChildren<FormBaseProps>) => {
    const { gap, policies, children, submit, loading, submitLabel = 'register', submitButtonWidth } = props;
    const captchaRef = useRef<ReCAPTCHA>(null);
    const { t } = useTranslation('pages/home');
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useFormContext();
    const { settings } = useSettings();

    const onSubmit = async (data: FieldValues) => {
        const captchaCode = await captchaRef?.current?.executeAsync();
        // @ts-ignore
        const { UtmCampaign, UtmContent, UtmMedium, UtmSource, UtmTerm } = useUtmParams();

        return submit({
            ...data,
            captchaCode,
            agreement: {
                ...data.agreement,
                touText: String(window.document.querySelector('.tou')?.innerHTML),
                touCheckBoxText: t('tou', {
                    touLink: `${window.location.origin}/terms-of-use`,
                }),
                privacyPolicyText: String(window.document.querySelector('.pp')?.innerHTML),
                privacyPolicyCheckBoxText: t('pp', {
                    confirmationLink: `${window.location.origin}/privacy-policy#personalDataInternshipProcessing`,
                    ppLink: '/privacy-policy',
                }),
                marketingCheckBoxText: t('marketing', {
                    confirmationLink: `${window.location.origin}/privacy-policy#personalDataInternshipProcessing`,
                    ppLink: '/privacy-policy',
                }),
            },
            utmCampaign: UtmCampaign,
            utmContent: UtmContent,
            utmMedium: UtmMedium,
            utmSource: UtmSource,
            utmTerm: UtmTerm,
        });
    };

    return (
        <Form gap={gap} loading={loading} onSubmit={handleSubmit(onSubmit)}>
            <Hidden>
                <Tou />
            </Hidden>
            <Hidden>
                <PrivacyPolicy />
            </Hidden>
            {children}
            {policies.map(({ name, label, required }) => (
                <Controller
                    key={name}
                    name={name}
                    control={control}
                    rules={{ required }}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => {
                        const [root, key] = name.split('.');

                        return (
                            <Checkbox
                                atSelector={`policy-${name}`}
                                value={value}
                                size={'medium'}
                                onChange={(value) => onChange(value)}
                                // @ts-ignore
                                isValid={!(errors && errors[root] && errors[root][key])}
                            >
                                <Disclaimer variant={'p6'} fontWeight={'regular'}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(label, { ADD_ATTR: ['target'] }),
                                        }}
                                    />
                                </Disclaimer>
                            </Checkbox>
                        );
                    }}
                />
            ))}

            <Disclaimer variant={'p6'} fontWeight={'regular'}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(t('captcha'), { ADD_ATTR: ['target'] }),
                    }}
                />
            </Disclaimer>

            {settings && (
                <ReCAPTCHA size={'invisible'} ref={captchaRef} badge="bottomright" sitekey={settings.captchaSiteKey} />
            )}

            <HeaderButton
                atSelector="submitButton"
                disabled={loading}
                style={{ marginTop: 'auto', width: submitButtonWidth ?? 170 }}
                type={'submit'}
            >
                {submitLabel}
            </HeaderButton>
        </Form>
    );
};

export default FormBase;
