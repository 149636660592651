import { useQuery } from '@apollo/client';
import GET_DIRECTIONS_GQL from 'api/queries/getDirections';
import GET_STUDY_TYPES_GQL from 'api/queries/getStudyTypes';
import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { Direction } from 'types';
import { StudyType } from 'types/study-type';
import { HowDidYouKnowType } from 'types/how-did-you_know-type';
import GET_HOW_DID_YOU_KNOW_TYPES_GQL from 'api/queries/getHowDidYouKnowTypes';

interface DirectionsContext {
    directions: Direction[] | null;
    studyTypes: StudyType[] | null;
    howDidYouKnowTypes: HowDidYouKnowType[] | null;
}

const DirectionsContext = createContext<DirectionsContext>({} as DirectionsContext);

const DirectionsProvider: FC<PropsWithChildren> = ({ children }) => {
    const [directions, setDirections] = useState<Direction[] | null>(null);
    const [studyTypes, setStudyTypes] = useState<StudyType[] | null>(null);
    const [howDidYouKnowTypes, setHowDidYouKnowTypes] = useState<HowDidYouKnowType[] | null>(null);

    useQuery(GET_DIRECTIONS_GQL, {
        variables: {
            page: 0,
            size: 50,
        },
        onCompleted: ({
            directions: {
                get: { items },
            },
        }) => {
            setDirections(items);
        },
    });

    useQuery(GET_STUDY_TYPES_GQL, {
        onCompleted: ({ homepageSettings: { studyTypes } }) => {
            setStudyTypes(studyTypes);
        },
    });

    useQuery(GET_HOW_DID_YOU_KNOW_TYPES_GQL, {
        onCompleted: ({ homepageSettings: { howDidYouKnowTypes } }) => {
            setHowDidYouKnowTypes(howDidYouKnowTypes);
        },
    });

    const memoValue = useMemo(
        () => ({
            directions,
            studyTypes,
            howDidYouKnowTypes,
        }),
        [directions, studyTypes, howDidYouKnowTypes]
    );

    return <DirectionsContext.Provider value={memoValue}>{children}</DirectionsContext.Provider>;
};

export const useDirections = () => useContext(DirectionsContext);

export default DirectionsProvider;
