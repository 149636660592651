import { Typography } from 'kl-b2c-ui-kit';
import React, { FC, useEffect } from 'react';
import { BaseWrapper } from 'styles/common';
import {
    Anchor,
    Chapter,
    CWrap,
    ListItem,
    PointList,
    SectionTitle,
    SubTitle,
    SupText,
    Table,
    TableBody,
    TableData,
    TableHead,
    TableHeadData,
    TableRow,
    Title,
    Paragraph,
} from './styled';

const PrivacyPolicy: FC = () => {
    useEffect(() => {
        const { hash } = window.location;

        const elementToScroll = document.getElementById(hash?.replace('#', ''));

        if (!elementToScroll) return;

        window.scrollTo({
            top: elementToScroll.offsetTop,
        });
    }, []);

    return (
        <CWrap className="pp">
            <BaseWrapper>
                <Title>
                    <Typography variant="h3">Политика конфиденциальности сайта safeboard.kaspersky.ru</Typography>
                </Title>
                <SubTitle>
                    <SectionTitle variant="h2">СВЕДЕНИЯ ОБ ОПЕРАТОРЕ ДАННЫХ</SectionTitle>
                </SubTitle>
                <Chapter>
                    <Paragraph variant="p1">
                        Настоящая Политика конфиденциальности применима к веб-сайту о карьере в АО {'"'}Лаборатория
                        Касперского
                        {'"'}&nbsp;(
                        <Anchor href="https://safeboard.kaspersky.ru" target="_blank" rel="noreferrer">
                            https://safeboard.kaspersky.ru
                        </Anchor>
                        ), расположенной по адресу: 125212, Российская Федерация, Москва, Ленинградское шоссе, д. 39А,
                        стр. 2 (далее {'"'}Лаборатория Касперского{'"'}, {'"'}Компания
                        {'"'} или {'"'}мы{'"'}).
                    </Paragraph>
                </Chapter>
                <Chapter>
                    <Paragraph variant="p1">
                        Если у вас есть вопросы о Политике конфиденциальности веб-сайта или вы хотите обновить
                        предоставленную нам информацию или предпочтения, обратитесь через форму о защите данных
                        здесь:&nbsp;
                        <Anchor href="https://support.kaspersky.ru/general/privacy" target="_blank" rel="noreferrer">
                            https://support.kaspersky.ru/general/privacy
                        </Anchor>
                    </Paragraph>
                </Chapter>
                <SubTitle>
                    <SectionTitle variant="h2">ОБЩАЯ ИНФОРМАЦИЯ</SectionTitle>
                </SubTitle>
                <Chapter>
                    <Paragraph variant="p1">
                        Вы можете посещать большинство страниц этого веб-сайта и читать размещенную на нем информацию
                        без предоставления каких-либо персональных данных.
                    </Paragraph>
                </Chapter>
                <Chapter>
                    <Paragraph variant="p1">
                        Чтобы предоставлять запрашиваемую вами информацию с наших веб-сайтов, наши серверы сохраняют в
                        анонимной форме некоторые данные в целях предоставления услуг и/или в статистических целях и
                        целях обеспечения безопасности. В этом случае сохраняются общие сведения, например, доменное имя
                        или тип вашего браузера. Эти данные не могут быть использованы нами для вашей идентификации.
                    </Paragraph>
                </Chapter>
                <Chapter>
                    <Paragraph variant="p1">
                        Мы не обрабатываем «специальные категории персональных данных» (сведения о расовой и этнической
                        принадлежности, религиозных или философских убеждениях, сексуальной жизни, сексуальной
                        ориентации, политических предпочтениях, участии в профессиональных союзах, а также сведения о
                        вашем состоянии здоровья, генетические и биометрические данные).
                    </Paragraph>
                </Chapter>
                <SubTitle id={'personalDataInternshipProcessing'}>
                    <SectionTitle variant="h2">Согласие на обработку данных</SectionTitle>
                </SubTitle>
                <Chapter>
                    <Paragraph variant="p1">
                        Во исполнение Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных» настоящим я
                        даю свое согласие АО «Лаборатория Касперского» производить в соответствии с законодательством
                        Российской Федерации и внутренними нормативными документами Компании обработку информации и
                        сведений обо мне, включая сбор, систематизацию, накопление, хранение, уточнение (обновление,
                        изменение), использование, обезличивание, блокирование и уничтожение персональных данных и иной
                        информации, сообщенной мной или моим представителем Компании, а также осуществлять хранение
                        такой информации в составе следующих персональных данных:
                    </Paragraph>
                </Chapter>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeadData>
                                <Paragraph variant="p1">Категории персональных данных</Paragraph>
                            </TableHeadData>
                            <TableHeadData>
                                <Paragraph variant="p1">Цель обработки</Paragraph>
                            </TableHeadData>
                            <TableHeadData>
                                <Paragraph variant="p1">Срок хранения</Paragraph>
                            </TableHeadData>
                            <TableHeadData>
                                <Paragraph variant="p1">Согласие на обработку данных</Paragraph>
                            </TableHeadData>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableData>
                                <PointList>
                                    <ListItem>
                                        <Paragraph variant="p1">Фамилия</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Имя</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Сведения об образовании</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Город проживания</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Номер телефона</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Адрес электронной почты</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Ник в telegram</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Рассказ о себе</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Интересующие направления стажировок</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Уровень английского</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">
                                            Количество часов в неделю, которые готов(а) уделять стажировке
                                        </Paragraph>
                                    </ListItem>
                                </PointList>
                            </TableData>
                            <TableData>
                                <Paragraph variant="p1">
                                    Проведение тестирования и дальнейшее рассмотрение кандидатуры для приёма на
                                    стажировку
                                </Paragraph>
                            </TableData>
                            <TableData>
                                <Paragraph variant="p1">5 лет</Paragraph>
                            </TableData>
                            <TableData>
                                <Paragraph variant="p1">
                                    Я разрешаю Компании обрабатывать мои персональные данные, а также передавать
                                    персональные данные и поручать их обработку третьим лицам с целью проведения
                                    тестирования и дальнейшего рассмотрения моей кандидатуры для приёма на стажировку в
                                    АО «Лаборатория Касперского»
                                </Paragraph>
                            </TableData>
                        </TableRow>
                        <TableRow>
                            <TableData>
                                <PointList>
                                    <ListItem>
                                        <Paragraph variant="p1">Фамилия</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Имя</Paragraph>
                                    </ListItem>
                                    <ListItem>
                                        <Paragraph variant="p1">Адрес электронной почты</Paragraph>
                                    </ListItem>
                                </PointList>
                            </TableData>
                            <TableData>
                                <Paragraph variant="p1">
                                    Осуществление маркетинговой деятельности Лабораторией Касперского, в том числе
                                    предоставление доступа к участию в событиях и мероприятиях, проводимых Лабораторией
                                    Касперского
                                </Paragraph>
                            </TableData>
                            <TableData>
                                <Paragraph variant="p1">5 лет</Paragraph>
                            </TableData>
                            <TableData>
                                <Paragraph variant="p1">
                                    Я разрешаю АО «Лаборатория Касперского» обрабатывать мои персональные данные, а
                                    также передавать персональные данные и поручать их обработку третьим лицам с целью
                                    осуществления АО «Лаборатория Касперского» маркетинговой деятельности
                                    <SupText>1</SupText>
                                </Paragraph>
                            </TableData>
                        </TableRow>
                    </TableBody>
                </Table>
                <Chapter>
                    <Paragraph variant="p1">
                        <SupText>1</SupText>
                        Применяется при получении согласия на получение маркетинговой информации.
                    </Paragraph>
                </Chapter>
                <Chapter>
                    <Paragraph variant="p1">
                        Я подтверждаю, что я проинформирован(а) о порядке отзыва настоящего согласия путем подачи
                        письменного заявления в АО «Лаборатория Касперского» в случаях, предусмотренных
                        законодательством РФ.
                    </Paragraph>
                </Chapter>
                <Chapter>
                    <Paragraph variant="p1">Мы получаем ваши личные данные от вас напрямую.</Paragraph>
                </Chapter>
                <SubTitle>
                    <SectionTitle variant="h2">
                        ЮРИДИЧЕСКОЕ ОСНОВАНИЕ ДЛЯ ОБРАБОТКИ ВАШИХ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </SectionTitle>
                </SubTitle>
                <Chapter>
                    <Paragraph variant="p1">Наши законные основания для обработки ваших персональных данных:</Paragraph>
                </Chapter>
                <PointList>
                    <ListItem>
                        <Paragraph variant="p1">
                            Ваше согласие на обработку персональных данных с целью рассмотрения кандидатуры для приёма
                            на стажировку
                        </Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph variant="p1">
                            Ваше согласие на обработку данных в целях получения маркетинговой информации
                        </Paragraph>
                    </ListItem>
                    <ListItem>
                        <Paragraph variant="p1">
                            Обработка, необходимая для выполнения требований действующего законодательства.
                        </Paragraph>
                    </ListItem>
                </PointList>
                <SubTitle>
                    <SectionTitle variant="h2">ГДЕ МЫ ОБРАБАТЫВАЕМ ДАННЫЕ</SectionTitle>
                </SubTitle>
                <Chapter>
                    <Paragraph variant="p1">
                        Персональные данные, предоставляемые пользователями этого сайта, обрабатываются на территории
                        России.
                    </Paragraph>
                </Chapter>
                <SubTitle>
                    <Paragraph variant="p1">ВРЕМЯ, В ТЕЧЕНИЕ КОТОРОГО МЫ ХРАНИМ ВАШИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ</Paragraph>
                </SubTitle>
                <Chapter>
                    <Paragraph variant="p1">
                        Персональные данные хранятся в течение 5 лет, пока пользователем не будет отозвано согласие на
                        обработку его персональных данных или до достижения целей обработки.
                    </Paragraph>
                </Chapter>
                <SubTitle>
                    <SectionTitle variant="h2">ИСПОЛЬЗОВАНИЕ COOKIES</SectionTitle>
                </SubTitle>
                <Chapter>
                    <Paragraph variant="p1">
                        Файлы cookie представляют собой файлы или фрагменты информации, которые могут быть сохранены на
                        вашем компьютере или других Интернет-совместимых устройствах (например, смартфон или планшет),
                        когда вы посещаете наш веб-сайт.
                    </Paragraph>
                </Chapter>
                <Chapter>
                    <Paragraph variant="p1">
                        На этом сайте мы используем cookies в виде электронных меток (веб-маяки) для получения
                        комплексной статистики в отношении эффективности наших рекламных кампаний и иных операций.
                    </Paragraph>
                </Chapter>
                <Chapter>
                    <Paragraph variant="p1">
                        Вы можете настроить параметры браузера таким образом, чтобы файлы cookie блокировались или ваша
                        система сообщала вам, когда веб-сайт хочет установить файлы cookie. Обратите внимание, что
                        блокирование файлов cookie может привести к тому, что вы не сможете использовать все функции
                        нашего веб-сайта.
                    </Paragraph>
                </Chapter>
                <Chapter>
                    <Paragraph variant="p1">
                        Мы также используем различные типы &quot;модулей для социальных сетей&quot;. Ознакомьтесь со
                        списком cookie, встроенных в наши веб-сайты и таблицей, разъясняющей, как мы используем модули
                        социальных сетей.
                    </Paragraph>
                </Chapter>
                <SubTitle>
                    <SectionTitle variant="h2">
                        ВАШИ ПРАВА И ВАШИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ И СПОСОБЫ ИХ РЕАЛИЗАЦИИ
                    </SectionTitle>
                </SubTitle>
                <PointList>
                    <ListItem>
                        Ваши права в отношении персональных данных предусмотрены действующим законодательством.
                    </ListItem>
                </PointList>
                <Chapter>
                    <Paragraph variant="p1">
                        Если вы хотите воспользоваться этими правами, вы можете в любое время связаться с нами через
                        форму по адресу{' '}
                        <Anchor href="https://support.kaspersky.ru/general/privacy" target="_blank" rel="noreferrer">
                            https://support.kaspersky.ru/general/privacy
                        </Anchor>
                    </Paragraph>
                </Chapter>
                <SubTitle>
                    <SectionTitle variant="h2">ЖАЛОБЫ</SectionTitle>
                </SubTitle>
                <Chapter>
                    <Paragraph variant="p1">
                        Если вы хотите подать жалобу в отношении данной Политики конфиденциальности или того, как мы
                        используем ваши Персональные данные, свяжитесь с нами, перейдя по{' '}
                        <Anchor href="https://support.kaspersky.ru/general/privacy" target="_blank" rel="noreferrer">
                            этой ссылке
                        </Anchor>
                        .
                    </Paragraph>
                </Chapter>
            </BaseWrapper>
        </CWrap>
    );
};

export default PrivacyPolicy;
